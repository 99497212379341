<template>
  <div>
    <v-dialog v-model="ModalDetail" persistent max-width="50%">
      <v-card>
        <v-card-title> Detail Jabatan </v-card-title>

        <v-divider class="mt-2 mb-5"></v-divider>

        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="SData.idBKN"
                label="ID JABATAN BKN"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense v-if="SData.formasi">
            <v-col cols="12" md="8">
              <v-select
                v-model="SData.formasi.id"
                label="Formasi Jabatan"
                :items="refformasi"
                item-value="id"
                item-text="nama"
                outlined
                dense
                readonly
                filled
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="SData.shift"
                label="Shift Kerja"
                :items="refshift"
                item-value="id"
                item-text="nama"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="SData.nama"
                label="Nama Jabatan"
                rows="2"
                outlined
                dense
                readonly
                filled
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="SData.alias"
                label="Alias Jabatan"
                rows="2"
                outlined
                dense
                readonly
                filled
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="SData.bup"
                label="Batas Usia Pensiun"
                type="number"
                outlined
                dense
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="SData.status"
                label="Status"
                :items="refstatus"
                item-value="id"
                item-text="nama"
                outlined
                dense
                readonly
                filled
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mt-n5"></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="gray" @click="closeModal()"> TUTUP </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";

import SView from "@/store/jabatan/pelaksana/view";
import SDetail from "@/store/jabatan/pelaksana/detail";

export default {
  computed: {
    ModalDetail: {
      get() {
        return SDetail.state.ModalDetail;
      },
      set(value) {
        SDetail.commit("TModal", value);
      },
    },

    SData: {
      get() {
        return SDetail.state.data;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async ModalDetail(value) {
      if (value) {
        this.refformasi = await getRef.formasi(this.token);
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),

    refformasi: [],
    refshift: [
      {
        id: "ya",
        nama: "YA",
      },
      {
        id: "tidak",
        nama: "TIDAK",
      },
    ],

    refstatus: [
      {
        id: "active",
        nama: "Aktif",
      },
      {
        id: "inactive",
        nama: "Tidak Aktif",
      },
    ],
  }),

  methods: {
    async closeModal() {
      await SView.commit("refreshData", true);
      this.ModalDetail = false;
    },
  },
};
</script>
